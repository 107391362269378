import FastResponseImage from '@resources/images/epd-cover-carousel-fast-response.png';
import RepairsByExpertsImage from '@resources/images/epd-cover-carousel-repairs-by-experts.png';
import EmergencyProtectionImage from '@resources/images/epd-cover-carousel-emergency-protection.png';
import EPDCoverWrenchImage from '@resources/images/EPDCoverWrench.png';
import EPDCoverSocketImage from '@resources/images/EPDCoverSocket.png';
import EPDCoverPipeImage from '@resources/images/EPDCoverPipe.png';
import TrustpilotDesktopImage from '@resources/images/EPDCoverTrustPilotLarge.webp';
import TrustpilotTabletImage from '@resources/images/EPDCoverTrustPilotMedium.png';
import TrustpilotMobileImage from '@resources/images/EPDCoverTrustPilotSmall.png';

export const heroHeading = 'Electrics, Plumbing and Drainage Cover.';
export const heroSubHeading = 'Protection against the costs of faults and emergency repairs.';

export const eligibilityModalTitle = 'You can get cover if:';
export const eligibilityModalBulletPoints = [
  'you’re a new or existing OVO customer.',
  'you’re the homeowner.',
  'your home is not a guest house or a B&B.',
  'you live in England, Wales, Scotland or the Isle of Wight.',
];
export const eligibilityModalAdditionalText =
  'Please note Electrics, Plumbing and Drainage Cover does not include boilers or central heating systems.';

export const coverCardLinkText = 'Cover summary';

export const howClaimsWorkSteps = [
  {
    title: 'Make a claim',
    description:
      'Log in to our claims page via the link below, or call us at any time, to get a repair booked in. Remember, if you choose cover with excess, you’ll pay £30 towards each repair.',
  },
  {
    title: 'Book a repair',
    description:
      'In emergencies, such as a burst pipe, we aim to send an engineer within 2 hours. For all other issues, we aim to send an engineer within the same working day if you call before 1pm – and within 24 hours if you call after that.',
  },
  {
    title: 'Get expert service',
    description:
      'Our engineers always aim to make the repair on the same day, or order the replacement parts. They’ll do their best to get everything back to normal as soon as possible. ',
  },
];

export const howClaimsWorkBackgroundPosition = {
  small: { bottom: '0px' },
  medium: { height: '485px', bottom: '0px' },
};

export const discoverMoreHeading = 'Cover you can count on';
export const discoverMoreSubHeading = '24/7 helpline support, and expert engineers on every job.';

export const discoverMoreCarouselCardData = [
  {
    imageSrc: FastResponseImage,
    title: 'Fast response',
    description:
      'We aim to repair your problem within 24 hours. Callouts are unlimited, and you can speak to our team 24/7, every day of the year.',
  },
  {
    imageSrc: RepairsByExpertsImage,
    title: 'Repairs by experts',
    description:
      'Our team of fully trained plumbers, electricians and drainage companies are reliable, friendly and experienced in fixing all types of issues.',
  },
  {
    imageSrc: EmergencyProtectionImage,
    title: 'Emergency protection',
    description:
      'Your home insurance might not cover electric, drainage and plumbing emergencies. Our cover brings peace of mind, without busting your budget.',
  },
];

export const coveredItemsData = [
  {
    id: 'expertRepairs',
    name: 'Repairs by expert plumbers and electricians',
    icon: 'boilerEngineer',
  },
  {
    id: 'costProtection',
    name: 'Protection against unexpected costs',
    icon: 'shield',
  },
  {
    id: 'unlimitedCallouts',
    name: '24/7 helpline\nUnlimited callouts',
    icon: 'energy-advice',
  },
];

export const getProtectionForCardData = [
  {
    image: EPDCoverSocketImage,
    heading: 'Electrics',
    bulletPoints: [
      'Fuse box faults',
      'Circuit breakers tripping',
      'Light switch faults',
      'Socket faults',
    ],
  },
  {
    image: EPDCoverPipeImage,
    heading: 'Plumbing',
    bulletPoints: [
      'Uncontrollable water leaks',
      'Tap, pipe and toilet leaks',
      'Toilet flushes not working',
      'Broken water supply pipes',
    ],
  },
  {
    image: EPDCoverWrenchImage,
    heading: 'Drainage',
    bulletPoints: [
      'Blocked or leaking drains',
      'Blocked or leaking waste pipes',
      'Internal and external drains and waste pipes',
    ],
  },
];

export const trustpilotBackgroundImages = {
  mobileImage: TrustpilotMobileImage,
  tabletImage: TrustpilotTabletImage,
  desktopImage: TrustpilotDesktopImage,
};
