import {
  Li,
  Margin,
  Modal,
  P,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Ul,
  TextLink,
  Strong,
} from '@ovotech/element';
import styled from 'styled-components';

import { planEpdCover } from '@fixtures/planEpdCover';
import { MarginDivider } from '@src/Styles/Common.Styles';
import { ROUTE_HOME } from '@constants/routes';

const TermsText = styled.div`
  ${({ theme }) => `
    p, li {
      color: ${theme.semantic.message.secondary};
    }
  `}
`;

interface WhatsIncludedEpdCoverModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WhatsIncludedEpdCoverModal = ({
  isOpen,
  onClose,
}: WhatsIncludedEpdCoverModalProps) => {
  return (
    <Modal
      title="Electrics, Plumbing and Drainage Cover summary"
      isOpen={isOpen}
      onClose={onClose}
      data-testid="epd-cover-whats-included"
    >
      <TabPanel>
        <Tabs>
          <TabList>
            <Tab>Included</Tab>
            <Tab>Not Included</Tab>
          </TabList>
          <TabPanel>
            <P>
              <Strong>We cover:</Strong>
            </P>
            <Margin vertical={4} />
            <P>
              <Strong>Electrics</Strong>
            </P>
            <Ul>
              <Li>fuse box, circuit breakers and sockets.</Li>
              <Li>
                light fittings including light bulb holders, light switches, dimmers, individual
                downlight fittings (embedded into ceilings) and fluorescent tube fitting.
              </Li>
            </Ul>
            <P>The electrical supply must be 240v and the damage must take place in your home.</P>
            <Margin vertical={4} />
            <P>
              <Strong>Plumbing and pipes</Strong>
            </P>
            <Ul>
              <Li>
                the internal hot and cold water pipes between the stopcock and your taps or
                appliances.
              </Li>
              <Li>leaking or dripping taps and the replacement of non-ceramic tap washers.</Li>
              <Li>
                leaking or running toilets including the toilet ballcocks, valves and syphons.
              </Li>
              <Li>stopcocks.</Li>
              <Li>
                repairing or replacing the damaged section of pipe in order to reconnect your home
                to the mains water supply. A permanent repair will only be carried out if the cost
                is the same or less than the temporary repair cost. Includes temporary reinstatement
                of any excavations carried out as part of the claim.
              </Li>
            </Ul>
            <Margin vertical={4} />
            <P>
              <Strong>Internal drainage</Strong>
            </P>
            <Ul>
              <Li>
                repairing or replacing leaking or blocked drain or waste pipes inside your home.
              </Li>
            </Ul>
            <Margin vertical={4} />
            <P>
              <Strong>External drainage</Strong>
            </P>
            <Ul>
              <Li>
                repairing or replacing leaking or blocked drain or waste pipes within the boundary
                of your property. Includes temporary reinstatement of any excavations carried out as
                part of the claim.
              </Li>
            </Ul>
            <MarginDivider type={'differentiated'} />
            <TermsText>
              <P>
                For full policy details, please see the{' '}
                <TextLink target="_blank" href={planEpdCover.terms}>
                  cover terms.
                </TextLink>
              </P>
              <Ul>
                <Li>The most we will pay for any claim is £2,000.</Li>
                <Li>
                  Replacements will only be carried out if the cost is the same or less than the
                  cost of repair.
                </Li>
              </Ul>
            </TermsText>
          </TabPanel>
          <TabPanel>
            <P>
              <Strong>We don’t cover:</Strong>
            </P>
            <Margin vertical={4} />
            <Ul>
              <Li>claims within the first 30 days.</Li>
              <Li>pre-existing faults.</Li>
              <Li>the first £30 of any claim, if you have chosen a policy with a £30 excess.</Li>
              <Li>
                the cost to gain access to pipes or wiring within walls, ceilings or under floors,
                or for the cost of any related redecoration or restoration.
              </Li>
            </Ul>
            <Margin vertical={4} />
            <P>
              <Strong>Boilers</Strong>
            </P>
            <Ul>
              <Li>annual boiler services.</Li>
              <Li>boiler replacements.</Li>
              <Li>central heating boilers.</Li>
              <Li>central heating systems.</Li>
              <Li>gas supply pipes.</Li>
            </Ul>
            <TextLink target="_blank" href={ROUTE_HOME}>
              See Boiler Cover options
            </TextLink>
            <Margin vertical={4} />
            <P>
              <Strong>Damage</Strong>
            </P>
            <Ul>
              <Li>accidental damage.</Li>
              <Li>pre-existing system design or installation faults.</Li>
              <Li>damage caused by weather or freezing.</Li>
              <Li>damage or blockages caused by degradation to pitch fibre drains.</Li>
            </Ul>
            <Margin vertical={4} />
            <P>
              <Strong>Electrics:</Strong>
            </P>
            <Ul>
              <Li>EV chargers</Li>
              <Li>
                devices that can be plugged into or operate using the electricity supply. For
                example, light bulbs, extractor fans, electric showers and domestic appliances.
              </Li>
              <Li>electrical faults that require a complete system rewire.</Li>
              <Li>fluorescent light starters, decorative or low voltage lighting.</Li>
            </Ul>
            <Margin vertical={4} />
            <P>
              <Strong>Plumbing, water supply pipes and drainage:</Strong>
            </P>
            <Ul>
              <Li>
                any water supply pipe outside the boundary of your home or for which you are not
                responsible. Or any water supply pipes under any building or outbuilding.{' '}
              </Li>
              <Li>steel or iron pipes.</Li>
              <Li>shared drains.</Li>
            </Ul>
            <MarginDivider type={'differentiated'} />
            <TermsText>
              <P>
                For full policy details, please see the{' '}
                <TextLink target="_blank" href={planEpdCover.terms}>
                  cover terms.
                </TextLink>
              </P>
            </TermsText>
          </TabPanel>
        </Tabs>
      </TabPanel>
    </Modal>
  );
};
