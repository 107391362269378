import { Heading4, Icon, Margin, theme } from '@ovotech/element';
import styled, { css } from 'styled-components';

import { CoveredItem } from '@entities/types';
import { InnerContainer } from '@src/modules/HomeRecoverVariation/ styled';
import { mqMediumDown, mqMediumUp, mqLargeUp } from '@utils/mediaQuery';

const StyledCoveredItem = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.core.space[4]};
    padding: ${theme.core.space[4]};
    border-bottom: solid ${theme.semantic.surface.base} 1px;

    ${mqMediumUp(`
      border-bottom: none;
      border-right: solid ${theme.semantic.surface.base} 1px;
      gap: ${theme.core.space[3]};
      padding: 0 ${theme.core.space[4]};
    `)};

    ${mqLargeUp(`
      gap: ${theme.core.space[4]};
      padding: ${theme.core.space[4]};
    `)};
  `
);

const CoveredItemWrapperWide = styled.div`
  ${({ theme }) => `
    background-color: ${theme.core.color.brand.white};
  `}
`;

const CoveredItemWrapper = styled(InnerContainer)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: ${theme.core.color.brand.white};
    padding: ${theme.core.space[8]} 0;
    justify-items: left;

    div:last-child {
      border: none;
    }

    ${mqMediumDown(`
      padding-top: ${theme.core.space[6]};
      padding-bottom: ${theme.core.space[6]};
      grid-template-columns: 1fr;
    `)}
  `
);

const IconWrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

const CoveredItemText = styled(Heading4)`
  ${({ theme }) => `
    color: ${theme.core.color.brand.black};
    font-weight: ${theme.core.fontWeight.bold};
    white-space: pre-wrap;

    ${mqMediumUp(`
      font-size: 16px;
    `)};

    ${mqLargeUp(`
      font-size: 24px;
    `)};
  `}
`;

interface CoveredItemProps {
  coveredItemsData: CoveredItem[];
}

export const CoveredItems = ({ coveredItemsData }: CoveredItemProps) => {
  return (
    <CoveredItemWrapperWide>
      <CoveredItemWrapper>
        {coveredItemsData.map((item) => {
          return (
            <StyledCoveredItem key={item.id}>
              <IconWrapper>
                <Icon name={item.icon} size={32} color={theme.core.color.brand.leaf} />
              </IconWrapper>
              <Margin right={2} inline>
                <CoveredItemText>{item.name}</CoveredItemText>
              </Margin>
            </StyledCoveredItem>
          );
        })}
      </CoveredItemWrapper>
    </CoveredItemWrapperWide>
  );
};
