import { Card, Heading4 } from '@ovotech/element';
import styled from 'styled-components';
import { useEffect } from 'react';

import { BulletWithIcon } from '@components/BulletWithIcon';
import { useCustomMixpanel } from '@services/Mixpanel';
import { MixpanelEvents } from '@entities/enums';
import { mqMediumUp, mqLargeUp } from '@utils/mediaQuery';

const RoundedCard = styled(Card)`
  ${({ theme }) => `
    position: relative;
    border-radius: 22px;
    padding: ${theme.core.space[4]} ${theme.core.space[6]} ${theme.core.space[6]};
    width: 310px;
    height: 372px;

    ${mqMediumUp(`
      min-height: 352px;
    `)};

    ${mqLargeUp(`
      width: unset;
      max-width: 365px;
      height: 372px;
    `)};
  `};
`;

const BlackHeading4 = styled(Heading4)`
  font-size: 32px;
  margin-top: 24px;
  margin-bottom: 16px;

  ${({ theme }) => `
    color: ${theme.core.color.brand.black};
  `};
`;

const BulletPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Image = styled.img`
  height: auto;
  width: 120px;
`;

export const GetProtectionForCard = ({
  image,
  heading,
  bulletPoints,
  isActive,
}: {
  image: string;
  heading: string;
  bulletPoints: string[];
  isActive?: boolean;
}) => {
  const { track } = useCustomMixpanel();

  useEffect(() => {
    if (isActive) {
      track(MixpanelEvents.CAROUSEL_SLIDE_ACTIVE, {
        carouselId: 'epdBenefitsUSP',
        slideId: heading,
      });
    }
  }, [isActive]);

  return (
    <RoundedCard>
      <Image src={image} />
      <BlackHeading4>{heading}</BlackHeading4>
      <BulletPointContainer id="bullet-container">
        {bulletPoints.map((bulletPoint, index) => (
          <BulletWithIcon
            flexStart
            key={index}
            icon={{ name: 'fill-green-tick', size: 16 }}
            text={bulletPoint}
          />
        ))}
      </BulletPointContainer>
    </RoundedCard>
  );
};
