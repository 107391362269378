import { Heading1, TextLink } from '@ovotech/element';
import styled from 'styled-components';
import { useState } from 'react';

import { GetProtectionForCard } from './GetProtectionForCard';
import { WhatsIncludedEpdCoverModal } from './WhatsIncludedEpdCoverModal';
import { GetProtectionForCarousel } from './GetProtectionForCarousel';

import { BrandCoreColor, GetProtectionForItem } from '@entities/types';
import bulbImage from '@resources/images/IllustrationBulb.png';
import { mqMediumUp, mqLargeUp } from '@utils/mediaQuery';
import { useMediaIs } from '@hooks/useMediaIs';

const Container = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    background-color: ${theme.core.color.brand.ovo};
    padding: 80px 24px;

    ${mqLargeUp(`
      padding: 136px 148px;
    `)};
  `};
`;

const Heading = styled(Heading1)`
  ${({ theme }) => `
    color: ${theme.core.color.brand.white};
    font-size: 48px;
    line-height: 100%;

    ${mqMediumUp(`
      font-size: 64px;
    `)};
  `};
`;

const HeadingContainer = styled.div`
  ${({ theme }) => `
    color: ${theme.core.color.brand.white};
    padding-bottom: 24px;

    ${mqMediumUp(`
      padding-bottom: 32px;
    `)};

    ${mqLargeUp(`
      padding-bottom: 48px;
    `)};
  `};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;

  ${mqLargeUp(`
    max-width: 1144px;
    align-self: center;
  `)};
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 2%;
  margin: 0 auto;
`;

const CheckWhatsIncluded = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    background-color: ${theme.core.color.brand.white};
    border-radius: 22px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: bold;
    font-size: 20px;
    margin-top: 40px;

    ${mqMediumUp(`
      justify-content: center;
      padding: 8px;
      text-align: center;
      font-size: 24px;
      max-width: 962px;
    `)};

    ${mqLargeUp(`
      align-self: center;
      margin-top: 32px;
      min-width: 100%;
    `)};
  `};
`;

const Image = styled.img`
  height: auto;
  width: 64px;
`;

const Link = styled(TextLink)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  cursor: pointer;

  ${mqMediumUp(`
    margin-left: 8px;
  `)};
`;

const CheckWhatsIncludedContent = styled.div`
  display: flex;
  flex-direction: column;

  ${mqMediumUp(`
    flex-direction: row;
    align-items: center;
  `)};
`;

interface GetProtectionForSectionProps {
  getProtectionForCardData: GetProtectionForItem[];
  sectionBackgroundColour: BrandCoreColor;
}

export const GetProtectionForSection = ({
  getProtectionForCardData,
  sectionBackgroundColour,
}: GetProtectionForSectionProps) => {
  const [isCoverModalOpen, setIsCoverModalOpen] = useState(false);

  const handleCoverModalOpen = () => {
    setIsCoverModalOpen(true);
  };

  const handleCoverModalClose = () => {
    setIsCoverModalOpen(false);
  };

  const isDesktop = !useMediaIs('lg', 'max');

  return (
    <Container>
      <ContentContainer>
        <HeadingContainer>
          <Heading>Get protection for:</Heading>
        </HeadingContainer>

        {isDesktop ? (
          <CardContainer>
            {getProtectionForCardData.map((item) => (
              <GetProtectionForCard
                key={item.heading}
                image={item.image}
                heading={item.heading}
                bulletPoints={item.bulletPoints}
              />
            ))}
          </CardContainer>
        ) : (
          <GetProtectionForCarousel
            cardData={getProtectionForCardData}
            sectionBackgroundColour={sectionBackgroundColour}
          />
        )}
        <CheckWhatsIncluded>
          <Image src={bulbImage} />
          <CheckWhatsIncludedContent>
            Check what’s included. <Link onClick={handleCoverModalOpen}>See details</Link>
          </CheckWhatsIncludedContent>
        </CheckWhatsIncluded>
      </ContentContainer>
      <WhatsIncludedEpdCoverModal isOpen={isCoverModalOpen} onClose={handleCoverModalClose} />
    </Container>
  );
};
